import { UpdateMarketingPreferencesResult, GetMarketingPreferencesResult } from '@AuroraTypes';

export function isValidMarketingPreferences(
  response: unknown,
): response is GetMarketingPreferencesResult {
  return (response as GetMarketingPreferencesResult)?.userId !== undefined;
}

export function isValidMarketingPreferencesUpdate(
  response: unknown,
): response is UpdateMarketingPreferencesResult {
  return (response as UpdateMarketingPreferencesResult)?.userId !== undefined;
}
